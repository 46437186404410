<template>
  <UModal v-model="visible">
    <UCard
      :ui="{
        ring: '',
        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        body: {
          base: 'max-w-lg',
          padding: 'sm:p-4'
        }
      }"
    >
      <div class="flex items-center justify-between">
        <h3
          class="text-base font-semibold leading-6 text-gray-900 dark:text-white" data-test="popup-assign-pack-header"
        >
          Assign {{ settingsPack?.name }}
        </h3>
        <UButton
          color="gray"
          variant="ghost"
          icon="i-heroicons-x-mark-20-solid"
          class="-my-1"
          @click="handleCloseModal"
        />
      </div>
      <span class="text-sm text-gray-500 dark:text-gray-400 mt-2">
        Your setting pack was already saved, choose boards to assign this setting pack to.
      </span>
      <div class="mt-6">
        <UInput
          v-model="search"
          icon="i-heroicons-magnifying-glass-20-solid"
          size="md"
          color="white"
          placeholder="Search boards"
          autofocus
          :trailing="false"
        />
      </div>
      <UForm
        :schema="schema" :state="formState" data-test="assign-settings-pack-form" class="mt-4"
        @submit="onSubmit"
      >
        <div class="space-y-1 mt-2">
          <template v-for="option in filteredBoards" :key="option.id">
            <div
              class="flex items-center gap-3 cursor-pointer hover:bg-gray-100 py-1.5 px-2 rounded-md"
              @click="handleSelectOption(option)"
            >
              <UCheckbox v-model="option.checked" />
              <Tooltip :text="option.name">
                <template #default="{ getTextRef }">
                  <span :ref="getTextRef" class="text-sm font-normal line-clamp-1 break-all">{{ option.name }}</span>
                </template>
              </Tooltip>
            </div>
          </template>
        </div>
        <div class="mt-6 flex justify-end">
          <UButton
            color="gray"
            class="mr-2"
            @click="handleCloseModal"
          >
            Cancel
          </UButton>
          <UButton :loading="isLoading" data-test="assign-pack-button" @click="onSubmit"> Assign </UButton>
        </div>
      </UForm>
    </UCard>
  </UModal>
  <SettingsPackUnassignModal
    v-model:visible="unAssignModalVisible"
    v-model:boards="unAssignedBoards"
    :settings-pack="settingsPack"
    @close="unAssignModalVisible = false"
    @updated="unAssignModalVisible = false"
  />
</template>

<script setup lang="ts">
import { z } from 'zod'
import type { SettingsPack, AssignedBoard } from '#settings-pack/types'
import type { BaseBoard } from '#board/types'

const props = defineProps({
  settingsPack: {
    type: Object as PropType<SettingsPack>,
    required: true,
  },
})

const visible = defineModel('visible', {
  type: Boolean,
  default: false,
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'updated'): void
}>()

const { currentWorkspace } = useWorkspaceSharedState()
const toast = useToast()
const { toggleAssignSettingsPack } = useToggleSettingsPack()

const schema = z.object({
  boards: z.array(z.string()),
  workspaceId: z.string(),
})

const formState = reactive({
  boards: [] as string[],
})

const isLoading = ref(false)
const search = ref('')
const listBoards = ref<BaseBoard[]>([])
const boards = ref<AssignedBoard[]>([])
const unAssignModalVisible = ref(false)
const unAssignedBoards = ref<BaseBoard[]>([])
const filteredBoards = computed(() => {
  return boards.value.filter(board => board.name.toLowerCase().includes(search.value.toLowerCase()))
})

const variables = computed(() => ({
  workspaceId: currentWorkspace.value.id,
}))

const { onResult, load } = useListWorkspaceBoardsLazyQuery(variables.value)

onResult(result => {
  listBoards.value = result?.data?.boards
})

const handleSelectOption = (option: AssignedBoard) => {
  option.checked = !option.checked
  const isBoardSelected = formState.boards.includes(option.id)
  if (!isBoardSelected) {
    formState.boards.push(option.id)
  } else {
    formState.boards = formState.boards.filter(id => id !== option.id)
  }
}

const onSubmit = async () => {
  unAssignedBoards.value = props.settingsPack?.boards?.filter(item => formState.boards.includes(item.id)) as BaseBoard[]
  if (unAssignedBoards.value.length > 0) {
    unAssignModalVisible.value = true
    formState.boards = []
    return emit('close')
  }

  isLoading.value = true

  await toggleAssignSettingsPack(props.settingsPack?.id, formState.boards)
  isLoading.value = false
  formState.boards = []
  emit('updated')
  toast.add({
    title: `Assigned settings pack successfully`,
  })
}

const handleCloseModal = () => {
  formState.boards = []
  emit('close')
}

watch(
  () => visible.value,
  async (value) => {
    if (value) {
      await load()
      boards.value = listBoards.value.map(board => ({
        name: board.name,
        id: board.id,
        checked: props.settingsPack?.boards?.some(assignedBoard => assignedBoard.id === board.id),
      }))
    }
  }
)
</script>
