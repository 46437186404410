<template>
  <div class="p-4 pb-3 rounded-md border border-gray-200">
    <div class="flex items-start gap-3">
      <div class="rounded-md mr-1 bg-blue-50 p-1">
        <IconTask />
      </div>
      <div class="flex flex-col text-gray-700 cursor-pointer" @click="showDetail=!showDetail">
        <div class="flex items-center gap-3">
          <div class="flex-1">
            <p class="text-sm font-medium text-gray-700">{{ settingsPackTaskTypes.length }} task types will be removed from board</p>
            <div class="text-xs leading-4 text-gray-500 mt-1">All tasks will be moved to the first task type at the same level and first status section on the destination board.</div>
          </div>
          <UIcon v-if="!showDetail" name="i-heroicons-chevron-right" class="w-4 h-4 ml-auto text-gray-700" />
          <UIcon v-else name="i-heroicons-chevron-down" class="w-4 h-4 ml-auto text-gray-700" />
        </div>
      </div>
    </div>

    <div v-if="showDetail" class="flex pt-3 border-t mt-3 pl-9">
      <div class="flex-1">
        <p class="text-xs font-bold text-gray-700 mb-2">Previous task types</p>
        <div class="flex flex-wrap gap-2">
          <div v-for="taskType in settingsPackTaskTypes" :key="taskType.id" class="flex gap-1 p-2 border rounded text-xs font-semibold w-fit">
            <TaskTypeIcon :background="taskType?.background" :icon="taskType?.icon || ''" size="xs" /> {{ taskType?.name }}
          </div>
        </div>
      </div>
      <div class="flex-1 flex justify-center items-center">
        <UIcon name="i-heroicons-arrow-right" class="size-6 text-gray-700" />
      </div>
      <div class="flex-1">
        <p class="text-xs font-bold text-gray-700 mb-2">New task type</p>
        <div class="flex gap-1 p-2 border rounded text-xs font-semibold w-fit">
          <TaskTypeIcon :background="defaultTaskType?.background" :icon="defaultTaskType?.icon || ''" size="xs" /> {{ defaultTaskType?.name }}
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 p-4 pb-3 rounded-md border border-gray-200">
    <div class="flex items-start gap-2">
      <div class="rounded-md p-1 mr-1.5 bg-blue-50">
        <IconTask />
      </div>
      <div>
        <p>Custom fields will be deleted</p>
        <div class="text-xs leading-4 text-gray-500 mt-1">Values for these fields will be back to default.</div>
      </div>
    </div>
  </div>
  <div class="mt-4 p-4 pb-3 rounded-md border border-gray-200">
    <div class="flex items-start gap-2">
      <div class="rounded-md p-1 mr-1.5 bg-yellow-50">
        <IconStatus />
      </div>
      <div>
        <p>Statuses</p>
        <div class="text-xs leading-4 text-gray-500 mt-1">Tasks in these status will be moved to the first cloumn status.</div>
      </div>
    </div>
  </div>
  <div class="mt-4 p-4 pb-3 rounded-md border border-gray-200">
    <div class="flex items-start gap-2">
      <div class="rounded-md p-1 mr-1.5 bg-green-50 size-6 text-green-500">
        <UIcon name="i-heroicons-tag-solid" size="16" />
      </div>
      <div>
        <p>Labels</p>
        <div class="text-xs leading-4 text-gray-500 mt-1">These labels will no longer run on this project.</div>
      </div>
    </div>
  </div>
  <div class="mt-4 p-4 pb-3 rounded-md border border-gray-200">
    <div class="flex items-start gap-2">
      <div class="rounded-md p-1 mr-1.5 bg-orange-50 size-6 text-orange-500">
        <UIcon name="i-heroicons-bolt-solid" size="16" />
      </div>
      <div>
        <p>Automations</p>
        <div class="text-xs leading-4 text-gray-500 mt-1">These automation will no longer run on this project.</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SettingsPack } from '#settings-pack/types'
import type { BaseTaskType } from '#task-type/types'
import { TaskLevel } from '#task/constant'

const props = defineProps({
  settingsPack: {
    type: Object as PropType<SettingsPack>,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  }
})

const { result } = useBoardDataQuery(props.boardId)
const defaultTaskType = computed(() => ((result.value?.boardData?.taskTypes || []) as BaseTaskType[]).find(taskType => taskType.level === TaskLevel.TASK && taskType.settingsPack === null))
const settingsPackTaskTypes = computed(() => props.settingsPack.taskTypes.filter((taskType) => taskType.level === TaskLevel.TASK))
const showDetail = ref(false)
</script>
