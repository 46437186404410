<template>
  <UModal v-model="visible">
    <UCard
      :ui="{
        ring: '',
        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        body: {
          padding: 'p-4 sm:p-4',
        },
      }"
    >
      <div class="flex items-center mb-2">
        <UButton
          v-if="selectedBoard?.id"
          :icon="'i-heroicons-chevron-left'"
          color="gray"
          size="xs"
          variant="ghost"
          class="mr-2.5"
          @click="resetSelectedBoard"
        />
        <h3
          class="text-lg font-semibold leading-6 text-gray-700 dark:text-white"
        >
          {{ title }}
        </h3>
      </div>
      <span v-if="removeAction && !selectedBoard?.id" class="text-sm text-gray-500">If you delete this setting pack, all related configurations will be removed from the following boards. This action cannot be undone. Are you sure you want to continue?</span>
      <span v-if="!removeAction && !selectedBoard?.id" class="text-sm text-gray-500 dark:text-gray-400">
        Unassign the {{ settingsPack?.name }} settings pack may significantly change how your project works.
      </span>
      <div v-if="!selectedBoard?.id && boards" class="text-sm leading-4 text-gray-500 mt-4 mb-2">Click on each board to view the specific changes.</div>
      <div>
        <div v-if="boards && !selectedBoard?.id" class="flex flex-col gap-2">
          <div
            v-for="board in boards" :key="board.id"
            class="px-4 py-3 rounded-md border border-gray-200 cursor-pointer"
            @click="selectedBoard = board"
          >
            <div class="flex items-center gap-2">
              <div
                :style="{
                  backgroundImage: `url(${getBackgroundUrl(
                    board,
                    BackgroundImageQuality.THUMB
                  )})`,
                }"
                class="size-6 rounded border bg-center bg-cover"
              ></div>
              <span
                class="line-clamp-1 break-all flex-1 text-left text-sm font-medium"
              >
                {{ board.name }}
              </span>
              <UIcon name="i-heroicons-chevron-right" class="w-4 h-4 ml-auto" />
            </div>
          </div>
        </div>
        <SettingsPackUnassignBoard
          v-if="boardId || selectedBoard?.id"
          :settings-pack="settingsPack"
          :board-id="boardId || selectedBoard.id"
        />
      </div>
      <div class="mt-3 flex justify-end">
        <UButton
          v-if="!selectedBoard?.id"
          color="gray"
          class="mr-2"
          @click="handleCloseModal"
        >
          Cancel
        </UButton>
        <UButton
          v-if="selectedBoard?.id"
          color="gray"
          class="mr-2"
          @click="resetSelectedBoard"
        >
          Back
        </UButton>
        <UButton v-if="removeAction && !selectedBoard.id" color="red" @click="handleRemove">Remove pack</UButton>
        <UButton v-if="!removeAction" color="red" @click="handleUnassign">Unassign</UButton>
      </div>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { SettingsPack } from '#settings-pack/types'
import type { BaseBoard } from '#board/types'
import { BackgroundImageQuality } from '#board/constant'

const props = defineProps({
  settingsPack: {
    type: Object as PropType<SettingsPack>,
    required: true,
  },
  boardId: {
    type: String,
    required: false,
  },
  removeAction: {
    type: Boolean,
    default: false,
  }
})

const visible = defineModel('visible', {
  type: Boolean,
  default: false,
})

const boards = defineModel('boards', {
  type: Array as PropType<BaseBoard[]>,
  default: () => [],
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'updated'): void
}>()

const toast = useToast()
const { setCurrentSettingsPack } = useWorkspaceSharedState()
const { deleteSettingsPack } = useDeleteSettingsPack()
const { toggleAssignSettingsPack } = useToggleSettingsPack()

const selectedBoard = ref<BaseBoard>({} as BaseBoard)

const title = computed(() => {
  if (selectedBoard.value?.name) {
    return selectedBoard.value?.name
  }

  if (props.removeAction) {
    return 'Delete setting pack?'
  }

  return 'Unassign settings pack?'
})

const resetSelectedBoard = () => {
  selectedBoard.value = {} as BaseBoard
}

const handleCloseModal = () => {
  selectedBoard.value = {} as BaseBoard
  emit('close')
}

const resetCurrentSettingsPack = () => {
  const { defaultSettingsPack } = useSettingsPackDataConvert()
  setCurrentSettingsPack(defaultSettingsPack)
}

const handleUnassign = async () => {
  const boardToUnassign = props.boardId ? [props.boardId] : boards.value.map(board => board.id)
  await toggleAssignSettingsPack(props.settingsPack?.id, boardToUnassign)
  selectedBoard.value = {} as BaseBoard
  emit('close')
  toast.add({
    title: `Unassigned settings pack successfully`,
  })
}

const handleRemove = async () => {
  await handleUnassign()
  const { error } = await deleteSettingsPack(
    props.settingsPack?.id
  )
  if (error) {
    return toast.add({
      title: 'Something went wrong',
      color: 'red',
    })
  }

  resetCurrentSettingsPack()
  toast.add({
    title: 'Deleted settings pack successfully',
  })
}
</script>
